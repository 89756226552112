@use "../../colors" as *;
@use "../../components/Forms/websiteForms" as *;

// .contactBgColor {
//   background-color: $prybgcolor;
// }

.sectionTwoBgColor {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 35px;
  height: 550px;
}
