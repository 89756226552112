$prytextcolor: #000000;
$sectextcolor: #0b78af;
$tertextcolor: #08ba47;
$btncolor: #0060c6;
$btntextcolor: #ffffff;
$prybgcolor: rgba(129, 229, 140, 0.5);
$secbgcolor: rgba(215, 232, 239, 0.5);
$terbgcolor: rgba(215, 232, 239, 0.3);

$dashboardcolor: linear-gradient(
    0deg,
    rgba(20, 129, 92, 0.5),
    rgba(20, 129, 92, 0.5)
  ),
  linear-gradient(0deg, #058547, #058547),
  linear-gradient(0deg, rgba(59, 192, 142, 0.1), rgba(59, 192, 142, 0.1)),
  #09cd1e;
