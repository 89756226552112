.bgColor {
  //   width: 35rem;
  height: 105px;
  /* pry/60 */

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(8, 186, 71, 0.6);
  border-radius: 10px;
}

.bgGreenColor {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(8, 186, 71, 0.6);
  border-radius: 10px;
}
