@use "../buttons/authenticationBtn" as *;

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

.justifyNavItemsEnd {
  justify-content: end !important;
}

.homeLink:hover {
  color: green !important;
}
.homeLink:focus {
  color: #08cd1d !important ;
  font-weight: bold;
}
