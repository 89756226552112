@use "../../colors" as *;

.SideNavItem {
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
}

.sideNavButton {
  // background: #fff;
  // color: $prybgcolor;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  height: 2.5rem;
  &:hover {
    color: #058547;
    background-color: #ffffff;
  }

  &:focus {
    background-color: #ffffff;
    color: #058547;
  }
}
