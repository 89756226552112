@use "../../colors" as *;

.videoIcon {
  font-size: 45px !important;
}

.scheduleASession {
  margin-left: 190px;
  .cardShadow {
    box-shadow: 4px 4px 7px 3px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    height: 10rem;
  }
  .hello {
    position: absolute;
    // width: 584px;
    height: 143px;
    // left: 113px;
    top: 220px;
  }
}

.dashboardProfileList {
  list-style-type: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: $btntextcolor;
}
.dashboardProfileCardsBorder {
  border: 1px solid #fff;
  // max-width: 280px;
  // max-height: 130px;
  border-radius: 20px;
  // background-color: #08ba47;
}
.dashboardProfileCardsBorder img {
  width: 100px;
  height: 100px;
}
.counselorsDashboardCard img {
  max-width: 400px;
}
.cardShadow {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.statFont {
  font-size: 1.35rem;
}
.textMutedFont {
  font-size: 1rem;
}
