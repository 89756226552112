* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  padding: 0 !important;
  overflow: visible !important;
}

h1 {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--white);
  font-family: "Montserrat", sans-serif;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

h4 {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

h5 {
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

h6 {
  font-size: 0.8rem;
  line-height: 1.3rem;
  font-family: "Montserrat", sans-serif;
}

p {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Comfortaa", cursive;
}

.parent {
  position: relative;
}

.evaluation-card {
  position: absolute;
  top: 130px;
  height: 71px;
  width: 250px;
  display: block;
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  display: block;
}

.gotYouCoveredCardBg {
  border-radius: 30px;
}

.Card1 {
  border: 5px;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  background-color: yellowgreen;
  width: 300px;
  height: 360px;
}

.whychooseus-card1 {
  grid-row-start: 2;
  grid-row-end: 3;
}

.whychooseus-card2 {
  grid-column-start: 2;
  grid-row-end: 2;
}

.whychooseus-card3 {
  grid-column-start: 3;
  grid-row-start: 2;
}

.whychooseus-card4 {
  grid-row-start: 3;
  grid-column-end: 3;
}

@media (min-width: 600px) {
  .ChooseCard {
    align-content: center;
    display: grid;
    gap: 0.1rem;
  }
  .Card1 {
    border: 5px;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    background-color: purple;
    width: 300px;
    height: 360px;
  }
  .whychooseus-card1 {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }
  .whychooseus-card2 {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }
  .whychooseus-card3 {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }
  .whychooseus-card4 {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }
}
.CardTestimony {
  border: 1px;
  border-style: solid;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  width: 300px;
  height: 360px;
}

.testimonialBoxShadow {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}

.BookaSessionBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 3rem;
  width: 11.5rem;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 11.5rem;
}

.sendBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 3rem;
  width: 11.5rem;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 8rem;
}

.submitFormBtn {
  width: 10rem;
  background-color: #0060c6;
  color: #fff;
}
.submitFormBtn:hover {
  background-color: #0060c6;
  color: #fff;
}

.bookNowBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 3rem;
  width: 11.5rem;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 9rem;
}

.proceedBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 3rem;
  width: 11.5rem;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 8rem;
}

.getInTouchBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 3rem;
  width: 11.5rem;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 10rem;
}

.saveBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 3rem;
  width: 11.5rem;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 8rem;
}

.takeATestBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 3rem;
  width: 11.5rem;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 10rem;
}

.startYourAssessmentBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 3rem;
  width: 11.5rem;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 16rem;
}

.TakeAssessmentBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 13rem;
  padding: 12px, 32px, 12px, 32px;
}

.BookASessionBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 13rem;
  padding: 12px, 32px;
}

.ContactBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 10rem;
}

.SubscribeBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 7.8rem;
  border-radius: 0px 10px 10px 0px;
}

.BookASessionNowBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 15rem;
  padding: 12px, 32px, 12px, 32px;
}

.ScheduleASessionBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 14rem;
  padding: 12px, 32px, 12px, 32px;
}

.BookaSessionBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 11.5rem;
}

.CheckStatusBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 10.2rem;
}

.RemoveNoteBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 10.5rem;
}

.EditNoteBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 8.4rem;
}

.ViewProfileBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 9.5rem;
}

.EditProfileBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 8.8rem;
}

.RemoveUserBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 10.4rem;
}

.AddUserBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 7.8rem;
}

.StartADiscussionBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 13rem;
}

.StartChattingBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 11.5rem;
}

.SaveBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 8.5rem;
}

.ContinueBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 11.5rem;
}

.GetStartedBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 9.5rem;
}

.videoIcon {
  font-size: 45px !important;
}

.scheduleASession {
  margin-left: 190px;
}
.scheduleASession .cardShadow {
  box-shadow: 4px 4px 7px 3px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  height: 10rem;
}
.scheduleASession .hello {
  position: absolute;
  height: 143px;
  top: 220px;
}

.dashboardProfileList {
  list-style-type: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #ffffff;
}

.dashboardProfileCardsBorder {
  border: 1px solid #fff;
  border-radius: 20px;
}

.dashboardProfileCardsBorder img {
  width: 100px;
  height: 100px;
}

.counselorsDashboardCard img {
  max-width: 400px;
}

.cardShadow {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.statFont {
  font-size: 1.35rem;
}

.textMutedFont {
  font-size: 1rem;
}

.aboutHeroColor {
  background: rgba(8, 186, 71, 0.1);
}

.SideNavItem {
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
}

.sideNavButton {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  height: 2.5rem;
}
.sideNavButton:hover {
  color: #058547;
  background-color: #ffffff;
}
.sideNavButton:focus {
  background-color: #ffffff;
  color: #058547;
}

.LoginBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 8rem;
}

.SignUpBtn {
  background: #0060c6;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  width: 8rem;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.justifyNavItemsEnd {
  justify-content: end !important;
}

.homeLink:hover {
  color: green !important;
}

.homeLink:focus {
  color: #08cd1d !important;
  font-weight: bold;
}

.HomePageHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 70vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
}

.HomePageHeroBg {
  background: url(/src/assets/images/hero/homeheroBg.png);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 70vh;
  width: 100%;
}

.HomePageHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.HomePageHeroBg h3 {
  position: relative;
  line-height: 35px;
}

@media only screen and (max-width: 990px) {
  .HomePageHeroBgg::before {
    height: 30rem;
  }
  .HomePageHeroBg::before {
    height: 30rem;
  }
}
@media only screen and (max-width: 765px) {
  .HomePageHeroBg {
    height: 140vh;
  }
}
.newsletterBg {
  background-color: #d7e8ef;
}

.newletterShadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
}

.communityBorder {
  border: 1px solid #0060c6 !important;
}

.AdvisoryImage {
  overflow: hidden;
  height: 300px;
  width: 300px;
  position: relative;
  cursor: pointer;
  margin: 0 15px;
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0px 50px;
}

.AdvisoryImage:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  transform: translate(-140%, -50%);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0px 50px;
  opacity: 0.8;
  border-radius: 50%;
  transition: 0.8s;
}

.AdvisoryImage:hover:after {
  transform: translate(-50%, -50%);
}

.AdvisoryImage:hover .AdvisoryIcon {
  transform: translate(-50%, -50%) scale(1.3);
}

.AdvisoryIcon {
  position: absolute;
  height: 110%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.8s;
}

.AdvisoryName {
  position: absolute;
  text-align: center;
  line-height: 90%;
  font-size: small;
  font-weight: bold;
  z-index: 2;
  top: 35%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #000;
  transition: 0.8s;
  transition-timing-function: ease-in;
}

.AdvisoryImage:hover .AdvisoryName {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.AdvisoryPosition {
  position: absolute;
  text-align: center;
  font-size: small;
  z-index: 2;
  top: 43%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #000;
  transition: 0.8s;
  transition-timing-function: ease-in;
}

.AdvisoryImage:hover .AdvisoryPosition {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.AdvisoryLink {
  position: absolute;
  text-align: center;
  font-size: xx-small;
  z-index: 2;
  top: 92%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #fff;
  transition: 0.8s;
  transition-timing-function: ease-in;
}

.AdvisoryImage:hover .AdvisoryLink {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.advisoryCard {
  border-radius: 0px 40px;
}

.advisoryBg {
  background-color: #14815c;
}

.formBg {
  background: rgba(255, 255, 255, 0.7);
  filter: blur(0.5px);
  border-radius: 35px;
}

.assessmentBg {
  background: none !important;
}

.assessmentSelection {
  background-color: white;
}

.assessmentBgColor {
  background: rgba(129, 229, 140, 0.5);
  border-radius: 10px;
  height: 1500px;
}

@media only screen and (max-width: 765px) {
  .assessmentBgColor {
    height: 2200px;
  }
}
.modal-header {
  border-bottom: none !important;
}

.formOptions {
  color: #5c5b5b;
  font-size: 0.85rem;
}

.bookingFormBg {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.bookingFormText {
  font-size: 0.85rem;
}

.newsletterFormShadow {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}

.sectionTwoBgColor {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 35px;
  height: 550px;
}

.BgColor {
  background: rgba(129, 229, 140, 0.5);
}

.gotYouCoveredBg {
  background: rgba(129, 229, 140, 0.3);
}

.counsellorCardBorder {
  border: 1px solid #000000;
  border-radius: 30px;
}

.accordion-button:not(.collapsed) {
  color: none !important;
  background-color: none !important;
}

.accordion-button:focus {
  border-color: none !important;
}

.button.accordion-button::after {
  background-image: url(/public/mindafrik.ico) !important;
}

.fontWeight {
  font-weight: 700;
}

.placeholderRadius {
  border: 1px solid #d8d7d7;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 3px 20px;
}

.errormsg {
  color: red;
}

.hello {
  color: red;
}

.auth-input-error {
  border: 1px solid red;
}

.auth-form-field {
  display: flex;
  align-items: center;
  border: 1px solid rgb(235, 232, 232);
  border-radius: 8px;
  width: 380px;
  height: 46px;
  background-color: rgb(236, 245, 248);
  transition: border-color 0.3s ease;
}

.auth-error-message {
  font-weight: 300;
  font-size: 0.65rem;
  color: red;
}

.formikFieldStyle {
  background: none !important;
  border: 0 !important;
  padding: 0 !important;
  outline: none !important;
  color: #000 !important;
}

.auth-otp-field {
  width: 3rem;
  height: 4.8rem;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgb(232, 232, 235);
  border-radius: 8px;
  transition: border-color 0.3s ease;
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  color: #b2adad;
}

.auth-otp-field::-moz-placeholder {
  color: rgb(175, 173, 173);
}

.auth-otp-field::placeholder {
  color: rgb(175, 173, 173);
}

.sectionOneBgColor {
  background: rgba(129, 229, 140, 0.5);
}

.sectionTwooBgColor {
  background: rgba(215, 232, 239, 0.3);
}

.bgColor {
  height: 105px;
  /* pry/60 */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(8, 186, 71, 0.6);
  border-radius: 10px;
}

.bgGreenColor {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(8, 186, 71, 0.6);
  border-radius: 10px;
}

.bgColor {
  background: rgba(129, 229, 140, 0.5);
  border-radius: 10px;
  height: 500px;
}

.emailVerifiedCard {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  background: #fff;
}
/*# sourceMappingURL=styles.css.map */