@use "../../colors" as *;
@use "../../font" as *;

// .WorksCard {
//   text-align: center;
// }
// .ChooseCard {
//   align-content: center;
//   display: grid;
//   gap: 0.1rem;
//   grid-template-columns: repeat(3, 1fr);
// }
.Card1 {
  border: 5px;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  background-color: yellowgreen;
  width: 300px;
  height: 360px;
}
.whychooseus-card1 {
  grid-row-start: 2;
  grid-row-end: 3;
}
.whychooseus-card2 {
  grid-column-start: 2;
  grid-row-end: 2;
}
.whychooseus-card3 {
  grid-column-start: 3;
  grid-row-start: 2;
}
.whychooseus-card4 {
  grid-row-start: 3;
  grid-column-end: 3;
}
@media (min-width: 600px) {
  .ChooseCard {
    align-content: center;
    display: grid;
    gap: 0.1rem;
  }
  .Card1 {
    border: 5px;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    background-color: purple;
    width: 300px;
    height: 360px;
  }
  .whychooseus-card1 {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }
  .whychooseus-card2 {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }
  .whychooseus-card3 {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }
  .whychooseus-card4 {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }
}

// .CardWork {
//   gap: 40px;
//   justify-content: space-between;
// }
.CardTestimony {
  border: 1px;
  border-style: solid;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  // background-color: purple;
  width: 300px;
  height: 360px;
}
.testimonialBoxShadow {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}
// @media (min-width: 600px){

// }
// .whychooseus-card2 {
//   grid-column-start: 2;
//   grid-row-end: 2;
// }
// .whychooseus-card3 {
//   grid-column-start: 3;
//   grid-row-start: 2;
// }
// .whychooseus-card4 {
//   grid-row-start: 3;
//   grid-column-end: 3;
// }
// @media (min-width: 600px) {
//   .ChooseCard {
//     align-content: center;
//     display: grid;
//     gap: 0.1rem;
//   }
//   .Card1 {
//     border: 5px;
//     box-sizing: border-box;
//     align-items: center;
//     text-align: center;
//     background-color: purple;
//     width: 300px;
//     height: 360px;
//   }
//   .whychooseus-card1 {
//     max-width: 1200px;
//     margin: 0 auto;
//     display: grid;
//     gap: 1rem;
//   }
//   .whychooseus-card2 {
//     max-width: 1200px;
//     margin: 0 auto;
//     display: grid;
//     gap: 1rem;
//   }
//   .whychooseus-card3 {
//     max-width: 1200px;
//     margin: 0 auto;
//     display: grid;
//     gap: 1rem;
//   }
//   .whychooseus-card4 {
//     max-width: 1200px;
//     margin: 0 auto;
//     display: grid;
//     gap: 1rem;
//   }
// }

// .CardTestimony {
//   border: 5px;
//   box-sizing: border-box;
//   align-items: center;
//   text-align: center;
//   background-color: purple;
//   width: 300px;
//   height: 360px;
// }

// @media screen and (min-width: 567px) {
//   .carousel-inner {
//     display: flex;
//   }
//   .carousel-item {
//     display: block;
//     margin-right: 0;
//     flex: 0 0 calc(100% / 3);
//   }
// }

// .carousel-inner {
//   padding: 1em;
// }
// .carousel-card {
//   margin: 0 0.5em;
// }

// .carousel-inner {
//   display: flex;
// }
// .carousel-card {
//   margin: 0 0.5em;
//   // width: calc(100% / 3);
//   // flex: 0 0 calc(100% / 3);
// }
