@use "../../components/Hero/homePageHero" as *;
@use "../../colors" as *;

.newsletterBg {
  background-color: #d7e8ef;
}
.newletterShadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
}

.communityBorder {
  border: 1px solid $btncolor !important;
}
