h1 {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--white);
  font-family: "Montserrat", sans-serif;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
h4 {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

h5 {
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

h6 {
  font-size: 0.8rem;
  line-height: 1.3rem;
  font-family: "Montserrat", sans-serif;
}

p {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Comfortaa", cursive;
}
