@use "../../colors" as *;
@use "../../font" as *;

// @mixin CounselorForm {
//   padding: 12px 20px;
//   margin: 8px 0px;
//   box-sizing: border-box;
//   border-radius: 50px;
//   // border: 2px solid $terbgcolor;
//   // background-color: $secbgcolor;
// }
// .form-control {
//   @include CounselorForm;
// }

// .placeholderRadius {
//   border: 1px solid #828282;
//   border-radius: 50px;
//   padding: 8px 20px;
// }
.placeholderRadius {
  border: 1px solid #d8d7d7;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 3px 20px;
}

// .bg-body-tertiary {
//   background-color: #b2adad;
// }

.errormsg {
  color: red;
}

.hello {
  color: red;
}

.auth-input-error {
  border: 1px solid red;
}

.auth-form-field {
  display: flex;
  align-items: center;
  border: 1px solid rgb(235, 232, 232);
  border-radius: 8px;
  width: 380px;
  height: 46px;
  background-color: rgb(236, 245, 248);
  transition: border-color 0.3s ease;
}

.auth-error-message {
  // width: 380px;
  font-weight: 300;
  font-size: 0.65rem;
  color: red;
  // padding: 2px;
}

.formikFieldStyle {
  background: none !important;
  border: 0 !important;
  padding: 0 !important;
  outline: none !important;
  color: #000 !important;
}

.auth-otp-field {
  width: 3rem;
  height: 4.8rem;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgb(232, 232, 235);
  border-radius: 8px;
  transition: border-color 0.3s ease;
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  color: #b2adad;
}
.auth-otp-field::placeholder {
  color: rgb(175, 173, 173);
}
