@use "../../colors" as *;

@mixin reoccuredBtnProperties {
  background: $btncolor;
  border-radius: 10px;
  height: 3rem;
  width: 11.5rem;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: $btntextcolor;
}
.BookaSessionBtn {
  @include reoccuredBtnProperties;
  width: 11.5rem;
}
.sendBtn {
  @include reoccuredBtnProperties;
  width: 8rem;
}
.submitFormBtn {
  // @include reoccuredBtnProperties;
  width: 10rem;
  background-color: $btncolor;
  color: #fff;
  &:hover {
    background-color: $btncolor;
    color: #fff;
  }
}
.bookNowBtn {
  @include reoccuredBtnProperties;
  width: 9rem;
}
.proceedBtn {
  @include reoccuredBtnProperties;
  width: 8rem;
}
.getInTouchBtn {
  @include reoccuredBtnProperties;
  width: 10rem;
}
.saveBtn {
  @include reoccuredBtnProperties;
  width: 8rem;
}

.takeATestBtn {
  @include reoccuredBtnProperties;
  width: 10rem;
}
.startYourAssessmentBtn {
  @include reoccuredBtnProperties;
  width: 16rem;
}

// @media screen and (max-width: 700px) {
//   .startYourAssessmentBtn {
//     @include reoccuredBtnProperties;
//     width: 18rem;
//     // padding: 25px 25px 25px 25px;
//   }
// }

@mixin Btns {
  background: $btncolor;
  border-radius: 10px;
  height: 2.7rem;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: $btntextcolor;
}
.TakeAssessmentBtn {
  @include Btns;
  width: 13rem;
  padding: 12px, 32px, 12px, 32px;
}
.BookASessionBtn {
  @include Btns;
  width: 13rem;
  padding: 12px, 32px;
}
.ContactBtn {
  @include Btns;
  width: 10rem;
}
.SubscribeBtn {
  @include Btns;
  width: 7.8rem;
  border-radius: 0px 10px 10px 0px;
}
.BookASessionNowBtn {
  @include Btns;
  width: 15rem;
  padding: 12px, 32px, 12px, 32px;
}
.ScheduleASessionBtn {
  @include Btns;
  width: 14rem;
  padding: 12px, 32px, 12px, 32px;
}

.BookaSessionBtn {
  @include Btns;
  width: 11.5rem;
}
.CheckStatusBtn {
  @include Btns;
  width: 10.2rem;
}
.RemoveNoteBtn {
  @include Btns;
  width: 10.5rem;
}
.EditNoteBtn {
  @include Btns;
  width: 8.4rem;
}
.ViewProfileBtn {
  @include Btns;
  width: 9.5rem;
}
.EditProfileBtn {
  @include Btns;
  width: 8.8rem;
}
.RemoveUserBtn {
  @include Btns;
  width: 10.4rem;
}
.AddUserBtn {
  @include Btns;
  width: 7.8rem;
}
.StartADiscussionBtn {
  @include Btns;
  width: 13rem;
}
.StartChattingBtn {
  @include Btns;
  width: 11.5rem;
}
.SaveBtn {
  @include Btns;
  width: 8.5rem;
}
.ContinueBtn {
  @include Btns;
  width: 11.5rem;
}
.GetStartedBtn {
  @include Btns;
  width: 9.5rem;
}
