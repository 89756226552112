@use "../../colors" as *;

.formBg {
  background: rgba(255, 255, 255, 0.7);
  filter: blur(0.5px);
  border-radius: 35px;
}
.assessmentBg {
  background: none !important;
}
.assessmentSelection {
  background-color: white;
}
.assessmentBgColor {
  background: rgba(129, 229, 140, 0.5);
  border-radius: 10px;
  //   width: 1031px;
  height: 1500px;
}
@media only screen and (max-width: 765px) {
  .assessmentBgColor {
    height: 2200px;
  }
}

.modal-header {
  border-bottom: none !important;
}

.formOptions {
  color: #5c5b5b;
  font-size: 0.85rem;
}
.bookingFormBg {
  // border: 1px solid #cce3fc !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.bookingFormText {
  font-size: 0.85rem;
}
.newsletterFormShadow {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}
